modal {
    div.modal {
        display: block;
    }

    div.modal-detached {
        position: absolute;
        bottom: 0; right: 0;
        width: 0; height: 0;
        visibility: hidden;
        pointer-events: none;
    }
}
