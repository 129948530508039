/**
 * nvd3 Charts Overrides
 */

// Revert root svg styling
svg {
    display: inline;
    width: auto;
    height: auto;
}

 // draws a circle around the data point on a time series line
 // this helps clearly indicate non-continuous data to the user
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
  fill-opacity: 1;
  stroke-opacity: 1;
  stroke-width:5px;
}
.nvd3.nv-line .nvd3.nv-scatter .nv-groups .nv-point.hover {
    stroke-width:8px;
}
.nv-x .tick text {
    text-anchor: start !important;
}
.nvd3 .nv-multibar .nv-groups rect:hover, .nvd3 .nv-multibarHorizontal .nv-groups rect:hover, .nvd3 .nv-discretebar .nv-groups rect:hover {
    opacity: 0.8;
}
.nvd3 text, .nvd3 .title, .nvtooltip {
    font-family: inherit;
    font-size: 14px;
}
.nvtooltip {
    font-size: 12px;
}
.nv-multibarHorizontal .nv-group {
    opacity: 0.6;
}

/*
* Activity Page dc.css overrides
*/
.activity-chart {
    width: 100%;
    min-height: 100px;
    position: relative;
}
.chart-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    text-align: center;
    padding-top: 30px;
}
div.dc-chart {
    float: none;
}
.dc-chart .axis text, .dc-chart .box text {
    font-family: inherit;
    font-size: 11px;
}
.dc-chart path.area {
    fill: none;
}
.dc-chart g.row rect, .dc-chart rect.bar {
    cursor: default;
}
